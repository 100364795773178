<template>
    <modal :open="modalOpen" @close="$emit('close');" :loading="followUpLoading" @save="followUpSubmit" :modalData="modalData">
        <template v-slot:modalContent>
            <div>
                <div class="text-h6 font-weight-bold text-capitalize pb-2 d-flex align-center">
                    Type:
                    <v-btn class="mx-2 secondary" :disabled="picklistLoading" x-small icon dark @click="getListData({force: 1})">
                        <v-icon dark size="15" v-if="!picklistLoading">mdi-sync</v-icon>
                        <v-progress-circular v-else size="15" :width="3" color="white" indeterminate></v-progress-circular>
                    </v-btn>
                </div>
                <div class="skeleton-list-item w-full mb-5" v-if="picklistLoading">
                    <v-skeleton-loader  :loading="true" type="list-item"></v-skeleton-loader>
                </div>
                <v-autocomplete class="mb-5" v-if="!picklistLoading"
                    :error-messages="$helpers.errorMsg('name', $v.followUpModel.type, 'Type')"
                    ref="followup_type"
                    v-model="followUpModel.type"
                    hide-details="auto" label="" placeholder="Select Type..."
                    :items="tyepLists" :menu-props="{ closeOnClick: true,  closeOnContentClick: true }"
                    outlined
                >
                </v-autocomplete>
                <div class="text-h6 font-weight-bold text-capitalize pb-2">
                enter description:
                </div>
                <v-textarea class="text-black" solo placeholder="Enter the description"
                auto-grow v-model="followUpModel.description"></v-textarea>
            </div>
        </template>
    </modal>  
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
    props: ["modalOpen"],
    emits: ["close", "save"],
    components:{
        Modal: () => import("@/components/common/modal"),
    },
    data() {
        return {
            modalData: {
                title: 'create follow up case',
                saveBtnTxt: 'Save',
            },
            tyepLists: [],
        }
    },
    computed: {
        ...mapGetters(["picklistLoading", "followUpLoading"]),

        followUpModel: {
            get() {
                return this.$store.getters["followUpModel"];
            },
            set(newValue) {
                return this.$store.commit("setFollowUpModel", newValue);
            },
        } 
    },
    validations() {
        return {
            followUpModel: {
                type: { required}
            }
        };
    },
    methods: {
        ...mapActions(["getPicklist"]),

        followUpSubmit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            if (this.$helpers.getCurData('curDonate')) {
                this.followUpModel.donation_id = this.$helpers.getCurData('curDonate');
                this.$emit('save', this.followUpModel);
            } else {
                this.$emit('close');
            }
        },
        getListData(data) {
            let reqData = {object: "Case", field: "Type"};
            if (data) reqData = {...reqData, ...data}
            this.getPicklist(reqData).then((response) => {
                this.tyepLists = response;
            });
        }
    },
    watch: {
        modalOpen: function (newValue) {
            if (newValue) {
                this.getListData();
            }
        }
    }
}
</script>
